.categoryitemContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 31px;
    width: 1394px;
    height: auto;
    .categoryItem{
        margin-right: 20px;
        width: 270px;
        height: 250px;
        img{
            width: 100%;
            height: 200px;
            margin: auto;
        }
        h5{
            text-transform: capitalize;
            font-family:'Inter', sans-serif;
            font-weight: 600;
            font-size: 14px;
            margin-top: 18px;
            color: var(--headText);
        }
    }
}

@media screen and (max-width: 480px){
    .categoryitemContainer {
            flex-direction: column;
            width: 100%;
    
            .categoryItem {
                width: 100%;
                padding: 16px 10px;
                margin-right: 0px;

                img{
                    
                }
    
                h5 {
                    text-transform: capitalize;
                    font-family: 'Inter', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    margin-top: 9px;
                    color: var(--headText);
                }
            }
        }
}


@media screen and (min-width: 481px) and (max-width: 768px){
    .categoryitemContainer {
            flex-direction: column;
            width: 100%;
            height: auto;
    
            .categoryItem {
                height: 300px;
                width: 70%;
                padding: 16px 10px;
                margin-right: 0px;
    
                img {
                    height: 250px;
                }
    
                h5 {
                    text-transform: capitalize;
                    font-family: 'Inter', sans-serif;
                    font-weight: 500;
                    font-size: 15px;
                    margin-top: 9px;
                    color: var(--headText);
                }
            }
        }
}