.venuedetailsheadContainer{
    padding: 0px 405px;
    padding-top: 100px;
    padding-bottom: 44px;
    .venuedetailsheadContents{
        width: 1110px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        .venuedetailsLeft{
            width: 80%;
            padding: 0px 10px;
            h4{
                font-family: 'Roboto', sans-serif;
                font-weight: 700;
                font-size: 24px;
                color: var(--black);
                text-transform: capitalize;
                margin-bottom: 10px;
            }

            .icon{
                &.address{
                    font-size: 16px;
                    color: var(--ratingColor);
                    margin-right: 10px;
                }
            }

            span{
                font-family: 'Inter', sans-serif;
                font-weight: 400;
                font-size: 14px;
                color: var(--black);
                text-transform: capitalize;
            }
        }

        .venuedetailsRight{
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
            button{
                width: 100%;
                font-family: 'Inter', sans-serif;
                font-weight: 600;
                font-size: 14px;
                text-transform: capitalize;
                padding: 11px 65px;
                background-color: var(--modifySearch);
                border-radius: 4px;
                cursor: pointer;
                display: inline-block;
                position: relative;
            }
        }
    }
}

@media screen and (max-width: 480px){
    .venuedetailsheadContainer {
            padding: 0px 0px;
            padding-top: 50px;
            padding-bottom: 44px;
            width: 100%;
    
            .venuedetailsheadContents {
                width: 100%;
                flex-direction: column;
    
                .venuedetailsLeft {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    margin-bottom: 30px;
                    h4 {
                        font-size: 30px;
                        margin-bottom: 10px;
                    }
                    .icon{
                    }
    
                    span {
                        font-size: 12px;
                    }
                }
    
                .venuedetailsRight {
                    width: 100%;
                    button {
                        font-size: 14px;
                    }
                }
            }
        }
}

@media screen and (min-width: 481px) and (max-width: 768px){

}