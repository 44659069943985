.heroContainer{
    width: 100%;
    height: 92vh;
    img{
        width: 100%;
        height: 92vh;
        background-repeat: no-repeat, repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        z-index: -10;
        filter: blur(3px);
            -webkit-filter: blur(3px);
    }
    .heroheadText{
        display: flex;
        align-items: center;
        justify-content: center;
        h3{
            font-family: 'Lemon', cursive;
            font-style: normal;
            font-weight: 400;
            font-size: 64px;
            text-transform: capitalize;
            color: var(--white);
            text-align: center;
            margin-top: 293px;
            text-shadow: 0px 5px 10px #00000099;
        }
    }
}

@media screen and (max-width: 480px){
    .heroContainer {
            img {
                
            }
    
            .heroheadText {
               
                h3 {
                    font-weight: 400;
                    font-size: 32px;
                    margin-top: 150px;
                }
            }
        }
}