.navContainer{
    width: 100%;
    height: 90px;
    .navbar{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        background-color: var(--loginNavbar);
        .logoImg{
            width: 30%;
            margin: auto;
            display: block;
            img{
                width: 100%;
                
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 480px){
    .navContainer{
        height: 60px;
    }
}