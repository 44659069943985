.modifysearchContainer{
    
    .modifysearchContents{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1110px;
        border-bottom: 1.5px solid #3333334d;
        .leftContent{
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 600px;
            padding: 40px 0px;
            .tab{
                width: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                span{
                    font-family: 'Inter', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    color: var(--black);
                    text-transform: capitalize;
                }

                .icon{
                    margin-right: 30px;
                    color: var(--headText);
                    font-size: 16px;
                }

            }
            
        }
        .rightContent{
            width: 510px;
            display: flex;
            align-items: center;
            margin-left: 334px;
            button{
                font-family: 'Inter', sans-serif;
                font-weight: 600;
                font-size: 14px;
                text-transform: capitalize;
                padding: 7px 18px;
                background-color: var(--modifySearch);
                border-radius: 4px;
                cursor: pointer;
            }

        }
    }
}


@media screen and (max-width: 480px){
    .modifysearchContainer {
        width: 100%;
            .modifysearchContents {
                width: 100%;
                flex-direction: column;
    
                .leftContent {
                    flex-direction: column;
                    width: 100%;
                    padding: 0px 0px;
                    padding-top:10px;
                    margin-bottom: 10px;
    
                    .tab {
                        width: 100%;
                        align-items: center;
                        justify-content: flex-start;
                        cursor: pointer;
                        padding: 10px 10px;
    
                        span {
                            font-weight: 500;
                            font-size: 14px;
                        }
    
                        .icon {
                            margin-right: 30px;
                            color: var(--headText);
                            font-size: 16px;
                        }
    
                    }
    
                }
    
                .rightContent {
                    width: 100%;
                    margin-bottom: 10px;
                    margin-left: 20px;

                    button {
                            //width: 100%;
                        }
    
                }
            }
        }
}