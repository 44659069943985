.navbarContainer {
    width: 100%;
    height:70px;
    //background-color: red;
    .navbarContent{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        background-color: var(--navbar);
        .leftContent{
            width: 50%;
            img{
                width: 20%;
                cursor: pointer;
                margin: auto;
                display: block;

            }
        }
        .rightContent{
            width: 50%;
            //margin-left: ;
            //width: 605px;
            ul{
                display: flex;
                align-items: center;
                flex-direction: row;

                li{
                    font-family: 'Roboto', sans-serif;
                    font-weight: 600;
                    font-size: 20px;
                    margin-right: 48px;
                    color: var(--navbarText);
                    cursor: pointer;                   
                }
                .navReg{
                    color: var(--navbarMenuRegText);
                }
            }
        }
    }
}


@media screen and (max-width: 480px) {
    
.navbarContainer {
        width: 100%;
        .navbarContent {
            width: 100%;
            .leftContent {
                width: 100%;
                img {
                    margin: 0;
                    display: flex;
                    margin-left: 40px;
                    width: 40%;
                }
            }

            .rightContent {
                display: none;
            }
        }
    }

}

@media screen and (min-width: 481px) and (max-width: 768px) {

    .navbarContainer {
        width: 100%;
        height: 90px;

        .navbarContent {
            .leftContent {
                width: 40%;
                img {
                    width: 50%;
                }
            }

            .rightContent {
                display: none;
            }
        }
    }

}