.detailsimgConatinar {
    padding: 0px 405px;
    margin-bottom: 71px;
    width: 100%;

    .rowoneConetents {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
        width: 1110px;

        .imgPrimary {
            width: 445px;
            height: 324px;
            margin: auto;
            display: block;
        }

        .imgWide {
            width: 633px;
            height: 324px;
            margin: auto;
            display: block;
        }
    }


    .rowtwoConetents {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 1110px;

        img {
            width: 539px;
            height: 359px;
            margin: auto;
            display: block;
        }
    }
    
}


@media screen and (max-width: 480px){
    .detailsimgConatinar {
            padding: 0px 0px;
            width: 100%;
    
            .rowoneConetents {
                width: 100%;
                padding: 0px 20px;
                flex-direction: column;
    
                .imgPrimary {
                    width: 100%;
                    height: 250px;
                    margin-bottom: 10px;
                }
    
                .imgWide {
                    width: 100%;
                    height: 250px;
                }
            }
    
    
            .rowtwoConetents {
                flex-direction: column;
                width: 100%;
                padding: 0px 20px;
    
                img {
                    width: 100%;
                    height: 250px;
                    margin-bottom: 10px;
                }
            }
    
        }
}

@media screen and (min-width: 481px) and (max-width: 768px){

}