.searchbyPrime{
        padding: 50px 405px;
        width: 100%;
        .searchbyContainer{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                background-color: var(--backgroundColor);
                border-radius: 4px;
                .searchtopContent{
                        width: 100%;

                }
                .searchbottomContent{
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        flex-direction: row;
                        padding: 20px 20px;
                }
}
}

@media screen and (max-width: 480px){
        .searchbyPrime {
                padding: 10px 0px;

                .searchbyContainer{
                        width: 100%;
                      .searchtopContent{
                        width: 100%;
                      }

                      .searchbottomContent{
                        width: 100%;
                        flex-direction: column;
                      }
                }
        }
}

@media screen and (min-width: 481px) and (max-width: 768px){

}