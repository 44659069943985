.offerContainer{
    padding: 0px 263px;
    padding-top: 100px;
    h4{
        text-transform: capitalize;
        font-size: 24px;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        color: var(--headText);
    }
}

@media screen and (max-width: 480px){
    .offerContainer {
            padding: 0px 0px;
            padding-top: 60px;
    
            h4 {
                text-transform: capitalize;
                font-size: 24px;
                font-family: 'Roboto', sans-serif;
                font-weight: 700;
                text-align: center;
                color: var(--headText);
            }
        }
}


@media screen and (min-width: 481px) and (max-width: 768px){
    .offerContainer {
            padding: 0px 0px;
            padding-top: 60px;
            width: 100%;
    
            h4 {
                text-transform: capitalize;
                font-size: 24px;
                font-family: 'Roboto', sans-serif;
                font-weight: 700;
                text-align: center;
                color: var(--headText);
            }
        }
}