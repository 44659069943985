.searchContainer{
    margin-top: 70px;
    width: 100%;
    .searchContents{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        .searchbyCategory{
            width: 209px;
            height: 53px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            border: 1px solid var(--searchFilterBox);
            border-radius: 4px;
            background-color: var(--searchFilterBox);
            margin-right: 40px;
            cursor: pointer;
            .icon{
                //margin-right: 16px;
                &.bar{
                    font-size: 25px;
                }
            }
            span{
                font-family: 'Inter', sans-serif;
                font-weight: 400;
                font-size: 16px;
                color: var(--black);
                text-transform: capitalize;
            }
        }

        .searchbyLocation{
            width: 209px;
            height: 53px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            border: 1px solid var(--searchFilterBox);
            border-radius: 4px;
            background-color: var(--searchFilterBox);
            margin-right: 40px;
            cursor: pointer;
            .icon{
                //margin-right: 16px;
                &.location{
                    font-size: 25px;
                }
            }
            span{
                font-family: 'Inter', sans-serif;
                font-weight: 400;
                font-size: 16px;
                color: var(--black);
                text-transform: capitalize;
            }
        }

        .searchbyDate{
            width: 209px;
            height: 53px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            border: 1px solid var(--searchFilterBox);
            border-radius: 4px;
            background-color: var(--searchFilterBox);
            margin-right: 40px;
            cursor: pointer;
            .icon{
                //margin-right: 16px;
                &.calendar{
                    font-size: 25px;
                }
            }
            input{
                font-family: 'Inter', sans-serif;
                width: 130px;
                height: 35px;
                font-size: 16px;
                padding: 10px;
                border-radius: 5px;

                &::placeholder{
                    text-align: center;
                }
            }
        }

        button{
            width: 53px;
            height: 53px;
            border-radius: 3px;
            background-color: var(--navbar);
            cursor: pointer;

            .icon {
                &.search{
                    font-size: 25px;
                    color: var(--SearchIcon);
                }
            }
        }

        
    }
}

@media screen and (max-width: 480px){
    .searchContainer {
        padding: 10px 0px;
        margin-top: 50px;
            .searchContents {
                flex-direction: column;
    
                .searchbyCategory {
                    flex-direction: row-reverse;
                    width: 250px;
                    height: 50px;
                    margin-right: 0px;
    
                    .icon {
    
                        //margin-right: 16px;
                        &.bar {
                            font-size: 20px;
                        }
                    }
    
                    span {
                        width: 150px;
                        font-weight: 300;
                        font-size: 14px;
                        text-align: center;
                    }
                }
    
                .searchbyLocation {
                    width: 250px;
                    height: 50px;
                    margin-top: 20px;
                    margin-right: 0px;
    
                    .icon {
    
                        //margin-right: 16px;
                        &.location {
                            font-size: 20px;
                        }
                    }
    
                    span {
                        width: 150px;
                        font-weight: 300;
                        font-size: 14px;
                        text-align: center;
                    }
                }
    
                .searchbyDate {
                    width: 250px;
                    height: 50px;
                    margin-top: 20px;
                    margin-right: 0px;
                    .icon {
    
                        //margin-right: 16px;
                        &.calendar {
                            font-size: 20px;
                        }
                    }
    
                    input {
                        width: 150px;
                        font-size: 14px;
                        padding: 10px;
                        border-radius: 5px;
    
                        &::placeholder {
                            text-align: center;
                        }
                    }
                }
    
                button {
                    width: 250px;
                    margin-top: 20px;
    
                    .icon {
                        &.search {
                            font-size: 25px;
                            color: var(--SearchIcon);
                        }
                    }
                }
    
    
            }
        }
}


@media screen and (min-width: 481px ) and (max-width: 768px){

    .searchContainer {
            padding: 10px 0px;
            margin-top: 50px;
            width: 100%;
    
            .searchContents {
                flex-direction: column;
    
                .searchbyCategory {
                    flex-direction: row-reverse;
                    justify-content: space-evenly;
                    width: 350px;
                    height: 70px;
                    margin-right: 0px;
    
                    .icon {
    
                        //margin-right: 16px;
                        &.bar {
                            font-size: 22px;
                        }
                    }
    
                    span {
                        width: 200px;
                        font-weight: 300;
                        font-size: 20px;
                        text-align: center;
                    }
                }
    
                .searchbyLocation {
                    justify-content: space-evenly;
                    width: 350px;
                    height: 70px;
                    margin-top: 20px;
                    margin-right: 0px;
    
                    .icon {
    
                        //margin-right: 16px;
                        &.location {
                            font-size: 22px;
                        }
                    }
    
                    span {
                        width: 200px;
                        font-family: 'Inter', sans-serif;
                        font-weight: 300;
                        font-size: 20px;
                        text-align: center;
                    }
                }
    
                .searchbyDate {
                    justify-content: space-evenly;
                    width: 350px;
                    height: 70px;
                    margin-top: 20px;
                    margin-right: 0px;
    
                    .icon {
    
                        //margin-right: 16px;
                        &.calendar {
                            font-size: 22px;
                        }
                    }
    
                    input {
                        font-family: 'Inter', sans-serif;
                        width: 200px;
                        height: 45px;
                        font-size: 20px;
                        padding: 10px;
                        border-radius: 5px;
    
                        &::placeholder {
                            text-align: center;
                            font-size: 20px;
                        }
                    }
                }
    
                button {
                    width: 350px;
                    margin-top: 20px;
    
                    .icon {
                        &.search {
                        }
                    }
                }
    
    
            }
        }
}