.categoryContainer{
    .categoryContents{
        padding: 0px 263px;
        padding-top: 100px;
        h3{
            text-transform: capitalize;
            font-family:'Roboto', sans-serif;
            font-weight: 700;
            font-size: 24px;
            color: var(--headText);
        }

    }
}

@media screen and (max-width: 480px){
    .categoryContainer {
            .categoryContents {
                padding: 0px 0px;
                padding-top: 60px;
    
                h3 {
                    text-transform: capitalize;
                    font-family: 'Roboto', sans-serif;
                    font-weight: 700;
                    font-size: 24px;
                    color: var(--headText);
                    text-align: center;
                    margin-bottom: 20px;
                }
    
            }
        }
}


@media screen and (min-width: 481px) and (max-width: 768px){
    .categoryContainer {
            .categoryContents {
                padding: 0px 0px;
                padding-top: 60px;
    
                h3 {
                    text-align: center;
                    margin-bottom: 20px;
                }
    
            }
        }
}
