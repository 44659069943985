.contents {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .socialHeading {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 12px;
        color: var(--black);
        margin-bottom: 18px;
    }

    .formSocialIcons {
        .icon {
            border: var(--loginSoicialIconBorder);
            padding: 4px;
            margin-right: 33px;
            margin-bottom: 21px;
            font-size: 40px;
            cursor: pointer;

            &.facebook {
                margin-left: 33px;
                color: var(--facebook);
            }

            &.googlePlus {
                color: var(--googlePlus);
            }

            &.twitter {
                color: var(--twitter);
            }
        }
    }
}