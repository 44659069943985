.searchitemContainer{
    width: 800px;
    height: auto;
    .searchitemContents{
        padding: 20px 20px;
        background-color: var(--white);
        box-shadow: 0px 4px 4px #0000001a;
        margin-bottom: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        .searchcontentsLeft{
            img{
                width: 254px;
                height: 190px;
                margin-right: 30px;
            }
        }

        .searchcontentsRight{
            width: 100%;
            .searchitemTop{
                width: 100%;
                display: flex;
                flex-direction: row;
                .leftInfo{
                    width: 70%;
                    h4{
                        font-family: 'Roboto', sans-serif;
                        font-weight: 700;
                        font-size: 20px;
                        color: var(--black);
                        margin-bottom: 10px;
                        text-transform: capitalize;
                        cursor: pointer;
                    }
                    .icon{
                        margin-right: 5px;
                        &.address{
                            font-size: 12px;
                            color: var(--ratingColor);
                        }
                    }
                    span{
                        font-family: 'Inter', sans-serif;
                        font-weight: 300;
                        font-size: 12px;
                        color: var(--black);
                        text-transform: capitalize;
                    }


                    h5{
                        margin-top: 33px;
                        margin-bottom: 26px;
                        font-family: 'Inter', sans-serif;
                        font-weight: 700;
                        font-size: 16px;
                        color: var(--black);
                        text-transform: capitalize;
                    }

                    p{
                        font-family: 'Inter', sans-serif;
                        font-weight: 300;
                        font-size: 12px;
                        color: var(--black);
                        text-transform: capitalize;
                    }
            }
                .rightInfo{
                    width: 180px;
                    .icon{
                        &.star{
                            color: var(--ratingColor);
                            margin-right: 10px;
                            font-size: 20px;
                        }
                    }

                    h5{
                        font-family: 'Inter', sans-serif;
                        font-weight: 700;
                        font-size: 12px;
                        text-decoration: line-through;
                        color: var(--oldPrice);
                        margin-top: 45px;
                    }

                    h3{
                        font-family: 'Inter', sans-serif;
                        font-weight: 700;
                        font-size: 24px;
                        color: var(--black);
                        margin-top: 11px;                    
                    }
                }
            }

            .searchitemBottom{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: row;
                margin-top: 11px;
                h4{
                    padding: 7px 18px;
                    font-family: 'Roboto', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    color: var(--black);
                    background-color: var(--backgroundColor);
                    border-radius: 3px;
                    margin-right: 21px;
                }
            }
        
        }
    }
}


@media screen and (max-width: 480px){
    .searchitemContainer {
        width: 100%;
            .searchitemContents {
                padding: 20px 20px;
                margin-bottom: 10px;
                flex-direction: column;
                .searchcontentsLeft {
                    width: 100%;
                    background-color: red;
                    margin-bottom: 15px;
                    img {
                        width: 100%;
                        margin: auto;
                        display: block;
                    }
                }
    
                .searchcontentsRight {
                    .searchitemTop {
    
                        .leftInfo {
                            width: 100%;
    
                            h4 {
                                font-size: 18px;
                            }
    
                            .icon {
    
                                &.address {
                                    font-size: 10px;
                                }
                            }
    
                            span {
                                font-size: 10px;
                            }
    
    
                            h5 {
                                margin-top: 16px;
                                font-size: 15px;
                            }
    
                            p {
                                font-size: 10px;
                            }
                        }
    
                        .rightInfo {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
    
                            .icon {
                                &.star {
                                    margin-right: 6px;
                                    font-size: 15px;
                                }
                            }
    
                            h5 {
                                font-size: 14px;
                                margin-top: 20px;
                            }
    
                            h3 {
                                font-size: 20px;
                                margin-top: 10px;
                            }
                        }
                    }
    
                    .searchitemBottom {
                        width: 100%;
                        margin-top: 15px;
                        align-items: center;
                        justify-content: center;

    
                        h4 {
                            width: 33%;
                            font-size: 10px;
                            border-radius: 2px;
                            margin-right: 10px;
                        }
                    }
    
                }
            }
        }
}