.venuereviewContainer{
    padding: 0px 401px;
    h4 {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 24px;
        color: var(--black);
        margin-bottom: 32px;
        text-transform: capitalize;
        }
    
    .viewMore{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 96px;
        button {
            text-transform: capitalize;
            cursor: pointer;
            font-family: 'Roboto', sans-serif;
            font-weight: 300;
            font-size: 19px;
            color: var(--navbarText);
            background-color: transparent;
        }
    }
    .venuereviewContent{
        margin-bottom: 32px;
        width: 1110px;
        .reviewBody{
            width: 100%;
            p {
                font-family: 'Roboto', sans-serif;
                font-weight: 300;
                font-size: 14px;
                color: var(--lightText);
                text-transform: capitalize;
            }
        }
        
        .venuereviewContents {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
        
                //margin-bottom: 32px;
                .venuereviewLeft {
                    width: 90%;
                    h5 {
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                        font-size: 20px;
                        color: var(--black);
                        text-transform: capitalize;
                        margin-bottom: 5px;
                    }
        
                    .week {
                        font-family: 'Roboto', sans-serif;
                        font-weight: 200;
                        font-size: 16px;
                        color: var(--black);
                        text-transform: capitalize;
                        margin-bottom: 16px;
                    }
                }
        
                .venuereviewRight {
                    width: 10%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .icon {
                        margin-right: 10px;
        
                        &.star {
                            color: var(--ratingColor);
                        }
                    }
        
                    span {
                        font-family: 'Roboto', sans-serif;
                        font-weight: 500;
                        font-size: 16px;
                        color: var(--black);
                    }
                }
            }
    }
        
}

@media screen and (max-width: 480px){
    .venuereviewContainer {
            padding: 0px 20px;
            width: 100%;
    
            h4 {
                font-size: 20px;
            }
    
            .viewMore {
    
                button {
                    font-size: 16px;
                }
            }
    
            .venuereviewContent {
                width: 100%;
    
                .reviewBody {
    
                    p {
                        font-size: 12px;
                        text-align: justify;
                    }
                }
    
                .venuereviewContents {
                    .venuereviewLeft {
                        width: 90%;
    
                        h5 {
                            font-size: 18px;
                        }
    
                        .week {
                            font-size: 14px;
                            margin-bottom: 10px;
                        }
                    }
    
                    .venuereviewRight {
                        span {
                            font-size: 14px;
                        }
                    }
                }
            }
    
        }
}

@media screen and (min-width: 481px) and (max-width: 768px){

}