.venuefacilitiesContainer{
    padding: 0px 401px;
    margin-bottom: 480px;
    
    .venuefacilitiesContents{
        width: 530px;
        h4{
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            font-size: 24px;
            color: var(--black);
            margin-bottom: 32px;
        }
        ul{
            
            li{
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: row;
                margin-bottom: 32px;
                p{
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    color: var(--lightText);

                }

                .icon{
                    margin-right: 18px;
                    &.rightArrow{
                        margin-top: 4px;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 480px){
    .venuefacilitiesContainer {
            padding: 0px 0px;
            margin-bottom: 480px;
    
            .venuefacilitiesContents {
                width: 100%;
                padding: 0px 20px;
    
                h4 {
                    font-size: 20px;
                }
    
                ul {
    
                    li {
    
                        p {
                            font-size: 14px;
    
                        }
    
                        .icon {
                            margin-right: 18px;
    
                            &.rightArrow {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
}

@media screen and (min-width: 481px) and (max-width: 768px){

}